<template>
  <div id="module-student">
    <CoolLightBox :items="coolLightImages" :index="indexViewImg" @close="indexViewImg = null" />
    <div class="card shadow filter" v-if="itemFilter" id="card-filter">
      <div class="card-header p-1">
        <div class="card-title w-100">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input type="search" class="form-control" id="filter_name" placeholder="Tìm kiếm" v-model="itemFilter.name"
              v-on:keyup="keyEnter" autocomplete="off">
            <div class="input-group-append">
              <button type="button" class="btn btn-info btn-sm" v-on:click="showHideFilter(showFilter)"
                style="width: 40px;">
                <i class="fas fa-chevron-left" v-if="!showFilter"></i>
                <i class="fas fa-chevron-down" v-if="showFilter"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pb-3" v-if="showFilter">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="form-group mb-0">
              <label for="filter_stadium">Sân vận động</label>
              <InputStadium ref="inputStadium" :province="userLogin.province_id" :district="userLogin.district_id"
                v-on:keyup="keyEnter" :callBack="setStadium" />
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="form-group">
              <label for="filter_stadium">Ngày học</label>
              <input type="date" class="form-control" v-model="itemFilter.date" id="date" placeholder="Ngày học"
                v-on:keyup="keyEnter" autocomplete="off">
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" style="border-bottom: 1px solid rgba(0,0,0,.125);" v-if="showFilter">
        <div class="btn-group float-right">
          <button type="button" class="btn btn-info btn-sm" v-on:click="initItems()"><i
              class="fas fa-search mr-2"></i><span>Tìm kiếm</span></button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="clearFilter()"><i
              class="fas fa-eraser mr-2"></i><span>Làm mới</span></button>
        </div>
      </div>
    </div>
    <div class="card shadow table-head-fixed">
      <div class="card-body p-0">
        <table class="table size-website">
          <thead>
            <tr id="attendance-header">
              <th style="width: 100px"></th>
              <th>
                <Order title="Tên" column="first_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Sân vận động" column="stadium_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Học phí" column="tuition_debt" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th style="width: 200px;">Điểm danh</th>
            </tr>
            <tr style="background: rgb(247, 247, 247)">
              <td colspan="5" class="text-right" style="border-top: none;">
                <div class="attendance-note">
                  <i>Đã điểm danh <span class="text-dark">{{ getAttendanceNumber(items) }}</span> trên tổng
                    <span class="text-dark">{{ items ? items.length : 0 }}</span> học viên</i>
                </div>
                <div id="attendance-note-temp">
                  <i>Đã điểm danh <span class="text-dark">{{ getAttendanceNumber(items) }}</span> trên tổng
                    <span class="text-dark">{{ items ? items.length : 0 }}</span> học viên</i>
                </div>
              </td>
            </tr>
          </thead>
          <tbody v-if="items">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-center" v-on:click="indexViewImg = index">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <router-link class="text-bold" v-bind:to="'/student/detail/' + item.student_id">
                  {{ item.first_name }} {{ item.last_name }}
                </router-link>
                <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
              </td>
              <td>
                <div>{{ item.stadium_name }}</div>
                <div><i class="text-sm">{{ item.stadium_address }}</i></div>
              </td>
              <td class="text-center">
                <i class="text-sm text-bold text-danger" v-if="item.tuition_debt == 1">Nợ học phí</i>
              </td>
              <td>
                <div class="icheck-info">
                  <input type="checkbox" :id="'attendance-' + index" v-model="item.attendance"
                    v-on:change="changeAttendance(item)" :disabled="!checkAttendance()">
                  <label :for="'attendance-' + index" class="w-100 m-0">
                    <span v-if="item.attendance">Có mặt</span>
                    <span v-else>Vắng mặt</span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table size-mobile">
          <thead>
            <tr id="attendance-header-mobile">
              <th style="width: 60px">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addStudent()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
              <th>Điểm danh</th>
            </tr>
            <tr style="background: rgb(247, 247, 247)">
              <td colspan="5" class="text-right" style="border-top: none;">
                <div class="attendance-note">
                  <i>Đã điểm danh <span class="text-dark">{{ getAttendanceNumber(items) }}</span> trên tổng
                    <span class="text-dark">{{ items ? items.length : 0 }}</span> học viên</i>
                </div>
                <div id="attendance-note-mobile-temp">
                  <i>Đã điểm danh <span class="text-dark">{{ getAttendanceNumber(items) }}</span> trên tổng
                    <span class="text-dark">{{ items ? items.length : 0 }}</span> học viên</i>
                </div>
              </td>
            </tr>
          </thead>
          <tbody v-if="items">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-center" v-on:click="indexViewImg = index">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <router-link class="text-bold" v-bind:to="'/student/detail/' + item.student_id">
                      {{ item.first_name }} {{ item.last_name }}
                    </router-link>
                    <span class="text-sm text-bold text-danger float-right" v-if="item.tuition_debt == 1">
                      Nợ học phí
                    </span>
                    <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
                    <div><i class="fas fa-futbol mr-2"></i>{{ item.stadium_name }}</div>
                    <div><i class="text-sm">{{ item.stadium_address }}</i></div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="icheck-info">
                      <input type="checkbox" :id="'attendance-' + index" v-model="item.attendance"
                        v-on:change="changeAttendance(item)" :disabled="!checkAttendance()">
                      <label :for="'attendance-' + index" class="w-100 m-0">
                        <span v-if="item.attendance">Có mặt</span>
                        <span v-else>Vắng mặt</span>
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ApiAttendance from "../../services/apiAttendance";
import { statusStudentOptions, dayInWeekOptions, statusPayOptions } from "../../common/constants";
import { getAdminAuthen } from "../../common/storage";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  components: {
    CoolLightBox,
    Order: () => import("../components/Order.vue"),
    InputStadium: () => import("../components/InputStadium.vue"),
  },
  data() {
    return {
      userLogin: null,
      coolLightImages: [],
      indexViewImg: null,
      showFilter: false,
      itemFilter: null,
      itemPaging: null,
      itemOrders: [{ column: "first_name", type: "asc" }],
      items: null,
      statusStudentOptions: statusStudentOptions,
      statusPayOptions: statusPayOptions,
      dayInWeekOptions: dayInWeekOptions,
      stadiumOptions: [],
      stadiumItem: { value: "", text: 'Chọn' },
      dateLoad: null,
    };
  },
  methods: {
    checkAttendance() {
      if (!this.dateLoad) return true;
      const date = this.formatDateYMD(this.dateLoad);
      const cur = this.formatDateYMD(new Date());
      if (date == cur || (date < cur && this.isAdmin()))
        return true;
      return false;
    },
    addStudent() {
      this.$router.push({ path: '/student', query: { action: "add" } });
    },
    getAttendanceNumber(items) {
      if (items && items.length > 0)
        return items.filter(e => e.attendance).length;
      return 0;
    },
    setStadium(provinceId, districtId, stadiumId) {
      console.log(provinceId, districtId, stadiumId);
      this.itemFilter.stadium = stadiumId;
      this.itemFilter.province = provinceId;
      this.itemFilter.district = districtId;
    },
    makeItemAddress() {
      return { province: "", district: "", stadium: "" };
    },
    makeItemFilter(user) {
      return { name: "", date: "", stadium: "", province: user ? user.province_id : "", district: user ? user.district_id : "" };
    },
    showHideFilter(value) {
      this.showFilter = !value;
    },
    clearFilter() {
      if (this.$refs && this.$refs.inputStadium)
        this.$refs.inputStadium.setStadium(this.makeItemAddress());
      this.itemFilter = this.makeItemFilter();
      this.initItems();
    },
    keyEnter: function (e) {
      if (e.which == 13) this.initItems();
    },
    async initItems() {
      this.showLoading(true);
      const data = await ApiAttendance.getAttendance(this.itemPaging, this.itemOrders, this.itemFilter);
      this.showLoading(false);
      this.items = [];
      this.coolLightImages = [];
      this.dateLoad = this.itemFilter ? this.itemFilter.date : null;
      if (data) {
        this.items = data.map(e => {
          e.attendance = e.status == 1;
          this.coolLightImages.push(this.fullPathFile(e.img));
          return e;
        });
      }
    },
    pagingBy(paging) {
      this.itemPaging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.itemOrders = orders;
      this.initItems();
    },
    async changeAttendance(item) {
      if (!item || !item.id || !this.checkAttendance()) return;
      this.showLoading(true);
      const itemPost = { "id": item.id, "status": item.attendance ? 1 : 0 };
      const data = await ApiAttendance.updateAttendance(itemPost);
      this.showLoading(false);
      this.checkResponse(data, async () => {
        // this.initItems();
      }, async () => {
        this.initItems();
        this.notifyToast('Không thành công', 'danger');
      });
    }
  },
  mounted() {
    this.userLogin = getAdminAuthen();
    this.itemFilter = this.makeItemFilter(this.userLogin);
    this.initItems();

    window.onscroll = function () {
      const mainHeader = document.getElementById("main-header");
      const cardFilter = document.getElementById("card-filter");
      const tableHeader = document.getElementById("attendance-header");
      if (mainHeader && cardFilter && tableHeader) {
        const noteTemp = document.getElementById("attendance-note-temp");
        const rectHeader = tableHeader.getBoundingClientRect();
        if (noteTemp && rectHeader) {
          if (rectHeader.y + tableHeader.offsetHeight < mainHeader.offsetHeight) {
            noteTemp.style.display = "block";
            noteTemp.style.top = `${window.scrollY - noteTemp.offsetHeight - cardFilter.offsetHeight + 16}px`;
          } else noteTemp.style.display = "none";
        }
      }
      const tableHeaderMobile = document.getElementById("attendance-header-mobile");
      if (mainHeader && cardFilter && tableHeaderMobile) {
        const noteTemp = document.getElementById("attendance-note-mobile-temp");
        const rectHeader = tableHeaderMobile.getBoundingClientRect();
        if (noteTemp && rectHeader) {
          if (rectHeader.y + tableHeaderMobile.offsetHeight < mainHeader.offsetHeight) {
            noteTemp.style.display = "block";
            noteTemp.style.top = `${window.scrollY - noteTemp.offsetHeight - cardFilter.offsetHeight + 16}px`;
          } else noteTemp.style.display = "none";
        }
      }
    };
  }
};
</script>
<style>
.attendance-note {
  color: rgb(127, 127, 127);
  margin: -8px 0;
  padding: 8px;
  width: 100%;
}

#attendance-note-temp,
#attendance-note-mobile-temp {
  display: none;
  position: absolute;
  color: rgb(127, 127, 127);
  background: rgb(247, 247, 247);
  margin: -8px;
  padding: 8px 16px;
  z-index: 10;
  width: 100%;
}
</style>
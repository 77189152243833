import DataService from "./dataService";
import config from "../common/config";
import { getPagingLimit } from "../common/storage";
import { getByPageOrder } from "../common/functions";

class ApiAttendance {
    _converFilter(filter) {
        var result = '';
        if (filter && filter.name) result += `&full_name=${filter.name}`;
        if (filter && typeof(filter.status) != 'undefined' && filter.status != "") result += `&status=${filter.status}`;
        if (filter && typeof(filter.tuition_debt) != 'undefined' && filter.tuition_debt != "") result += `&tuition_debt=${filter.tuition_debt}`;
        if (filter && filter.stadium) result += `&stadium_id=${filter.stadium}`;
        if (filter && filter.province) result += `&province_id=${filter.province}`;
        if (filter && filter.district) result += `&district_id=${filter.district}`;
        if (filter && filter.date) result += `&date=${filter.date}`;
        return result;
    }
    getAttendance(paging, orders, filter) {
        const limit = getPagingLimit();
        return DataService.get(`/attendance?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }${this._converFilter(filter)}`);
    }
    getAttendanceBy(payId) {
        return DataService.get(`/attendance_by?pay_id=${payId}`);
    }
    addAttendance(data) {
        return DataService.put("/attendance", data);
    }
    updateAttendance(data) {
        return DataService.post("/attendance", data);
    }
    deleteAttendance(id) {
        return DataService.delete(`/attendance?id=${id}`);
    }
}

export default new ApiAttendance();